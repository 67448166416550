<template>
  <div class="Propage">
    <!-- 产品详情协议 -->
    <div v-if="protocolList">
      <div class="proMain" v-for="item in protocolList" :key="item.key">
        <a class="prolink" @click="JumpLink(item.value, item.key)">
          <div class="proLine">
            <div class="proText">{{ item.key }}</div>
            <van-icon class="proIcon" name="arrow"/>
          </div>
        </a>
      </div>
    </div>
    <div v-if="protocolLists">
      <div class="proMain" v-for="item in protocolLists" :key="item">
        <a class="prolink" @click="JumpLink(item.contractUrl, item.contractName)">
          <div class="proLine">
            <div class="proText">{{ item.contractName }}</div>
            <van-icon class="proIcon" name="arrow"/>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<style scoped lang='less' src='./index.less'></style>
<script>
import {onMounted, toRefs, reactive} from 'vue'
import {Jump} from '@/utils/routh'
import {isEmptyObj} from '../../utils/index'
import API from '@/server/api'
import {useRoute} from 'vue-router'

export default {
  setup() {
    const route = useRoute()
    const state = reactive({
      newList: [],
      newObj: null,
      protocolList: [],
      protocolLists: [],
      loanNo: '',
      type: 0,
    })

    // 获取用户协议
    const initProtocolTwo = async () => {
      try {
        const res = await API.getProtocol()
        if (res.success) state.protocolList = res.protocolList
      } catch (err) {
        console.log(err.message)
      }
    }


    const initProtocol = async () => {
      //type 4 授信协议
      try {
        const res = await API.getProtocols({
          type: state.type,
          loanNo: state.loanNo
        })
        if (res.success) state.protocolLists = res.data
      } catch (err) {
        console.log(err.message)
      }
    }

    // 页面跳转
    const JumpLink = (item, items) => {
      Jump(item, items)
    }


    onMounted(async () => {
      state.loanNo = localStorage.getItem('loanNos')
      const query = route.query
      if(!isEmptyObj(query) && query.num){
        const num = query.num
        switch (num) {
          case '3':
            document.title = "还款协议"
            state.type = 3
            await initProtocol()
            break;
          case '4':
            document.title = "授信协议"
            state.type = 4
            await initProtocol()
            break
        }
      }else{
        await initProtocolTwo()
      }
    })
    return {
      ...toRefs(state),
      JumpLink
    }
  }
}
</script>
